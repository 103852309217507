import {I$W, IControllerConfig, IWidgetController} from '@wix/native-components-infra/dist/src/types/types';
import {IControllerFactoryConfig} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/createViewerScript';
import {AddToCartStore} from './AddToCartStore';
import {SPECS} from '../constants';
import {Scope} from '@wix/app-settings-client/dist/src/domain';

export function addToCartController({
  context,
  config,
  setProps,
  reportError,
  compId,
  type,
  wixCodeApi,
}: IControllerFactoryConfig): IWidgetController {
  let addToCartStore: AddToCartStore;
  const {siteStore} = context;
  const {publicData, externalId = ''} = config;

  return {
    pageReady: () => {
      addToCartStore = new AddToCartStore(
        publicData,
        setProps,
        siteStore,
        externalId,
        reportError,
        compId,
        type,
        wixCodeApi
      );

      return addToCartStore.setInitialState().catch(reportError);
    },
    updateConfig: (_$w: I$W, {publicData: newPublicData}: IControllerConfig) => {
      addToCartStore.updateState(newPublicData);
    },
    updateAppSettings: (_$w: I$W, updates) => {
      if (
        siteStore.experiments.enabled(SPECS.EDITOR_OOI) &&
        updates.scope === Scope.COMPONENT &&
        updates.source === 'app-settings'
      ) {
        addToCartStore.updateAppSettings(updates.payload);
      }
    },
  };
}
